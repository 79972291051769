import React, { useState } from 'react';

const questions = [
  // Your detailed questions here...
   "To what extent do you find initiating or participating in casual social interactions, like small talk or informal gatherings, more challenging than others might?",
    "How strongly do you feel the need to adhere to established routines, and how much distress do you experience when those routines are unexpectedly altered?",
    "Consider your level of interest in specific subjects or hobbies. How intensely are you drawn to these, perhaps to the point of excluding other activities?",
    "Reflect on your ability to understand and empathize with others' emotions. To what degree do you find this challenging, leading to possible misunderstandings?",
    "Evaluate your sensitivity to sensory stimuli such as sounds, lights, or textures. How much more affected are you by these stimuli compared to the average person?",
    "Think about your reaction to idioms, metaphors, and sarcasm. To what extent do you find these forms of non-literal language difficult to interpret?",
    "Consider your preference for solitary over group activities. How much more do you enjoy being alone or engaging in activities by yourself?",
    "To what degree do you prefer structured activities with clear rules and expectations over spontaneous or unstructured activities?",
    "Reflect on how literally you interpret communication. To what extent do you tend to take things said to you at face value, missing implied meanings?",
    "Think about any repetitive behaviors, routines, or rituals you might engage in. How much do these behaviors provide you with comfort or a sense of control?"
];

const Questionnaire = () => {
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [responses, setResponses] = useState([]);
  const [averagePercentage, setAveragePercentage] = useState(null);

  const handleResponse = (event) => {
    event.preventDefault();
    const response = parseFloat(event.target.percentage.value);
    if (isNaN(response) || response < 0 || response > 100) {
      alert("Please enter a valid percentage between 0 and 100.");
      return;
    }
    const newResponses = [...responses, response];
    setResponses(newResponses);
    if (currentQuestionIndex < questions.length - 1) {
      setCurrentQuestionIndex(currentQuestionIndex + 1);
    } else {
      const average = newResponses.reduce((acc, curr) => acc + curr, 0) / questions.length;
      setAveragePercentage(average.toFixed(2));
    }
    event.target.reset();
  };

  return (
    <div>
      {averagePercentage === null ? (
        <form onSubmit={handleResponse}>
          <h2>Question {currentQuestionIndex + 1}</h2>
          <p>{questions[currentQuestionIndex]}</p>
          <input type="text" name="percentage" placeholder="Enter a percentage (0-100)" />
          <button type="submit">Submit</button>
        </form>
      ) : (
        <div>
          <h2>Your Results</h2>
          <p>Based on this questionnaire, your average level of agreement with traits commonly associated with autism spectrum conditions is {averagePercentage}%.</p>
          <p>Please remember: This tool is not a diagnostic instrument and is for informational purposes only. If you have concerns, seeking a formal assessment from a healthcare professional is advised.</p>
          <button onClick={() => window.location.reload()}>Restart</button>
        </div>
      )}
    </div>
  );
};

export default Questionnaire;
